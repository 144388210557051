import styled from "styled-components"
import { colors } from "../../themes/colors"

export const DropdownWrapper = styled.div<{
  error: boolean
  isFocused: boolean
}>`
  padding: 12px;
  border: 1px solid
    ${({ error }) => (error ? colors.c_red[600] : colors.c_primary[600])};
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr 24px;
  gap: 8px;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  background-color: ${colors.c_white[50]};

  span {
    justify-self: flex-start;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    width: 100%;
  }
  ${({ isFocused }) =>
    isFocused && "box-shadow: 0px 0px 0px 2px rgba(81, 69, 158, 0.24)"};
  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    max-width: 480px;
  }
`

export const DropdownMenu = styled.div`
  position: absolute;
  background-color: ${colors.c_white[100]};
  width: 100%;
  text-align: left;
  max-height: 320px;
  overflow-y: auto;
  top: 58px;
  left: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(28, 30, 33, 0.16);
  z-index: 2;

  @media (max-width: 768px) {
    max-width: 480px;
  }
`

export const DropdownItem = styled.div<{ selected: boolean }>`
  padding: 12px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-family: Poppins;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  ${({ selected }) =>
    selected ? `background-color: ${colors.c_grey[100]};` : ""}
`

export const ErrorTooltip = styled.div`
  position: absolute;
  bottom: -48px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 4px;
  padding: 8px 16px;
  background-color: ${colors.c_red[50]};
  border: 1px solid #d1d3d7;
  width: max-content;
  pointer-events: none;
  z-index: 2;

  p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  img {
    position: absolute;
    top: -7px;
    left: calc(50% - 7.5px);
  }
`
