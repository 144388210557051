import { useEffect, useState } from "react"
import SEO from "../SEO"
import { Navigation } from "../ui/Navigation"
import { useStaticQuery, graphql } from "gatsby"
import { ConditionsLPWrapper, ScrollToTopButton } from "./styled"
import {
  ConditionsWeTreat,
  ConditionSections,
  OtherSpecialties,
  TherapeuticApproaches,
  MedsVsTherapy,
} from "./sections"
import { ConditionsPageProps } from "./types"
import { organizationSchemaMarkup } from "../../common/OrganizationSeoSchema"
import { MoreGoodDays } from "../shared/MoreGoodDays"
import { FAQSection } from "../shared/FAQSection"
import ArrowUp from "./assets/arrow-up.svg"

const CONDITIONSFAQ_PAGE_QUERY = graphql`
  query ConditionsFAQPageQuery {
    allPrismicFaqWidget(filter: { data: { page_type: { eq: "conditions" } } }) {
      edges {
        node {
          data {
            faqs {
              question {
                document {
                  ... on PrismicFaqRedesign {
                    data {
                      answer {
                        html
                      }
                      question {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const ConditionsLPBody = ({
  data,
}: {
  data: ConditionsPageProps
}): JSX.Element => {
  const response = useStaticQuery(CONDITIONSFAQ_PAGE_QUERY)
  const faqs = response.allPrismicFaqWidget.edges[0].node.data.faqs
  const [selectedCondition, setSelectedCondition] = useState<string | null>(
    null
  )

  const [showScrollUp, setShowScrollUp] = useState(false)

  useEffect(() => {
    if (selectedCondition) {
      const el = document.getElementById(selectedCondition)
      if (el) {
        const boundingRect = el.getBoundingClientRect()
        window.scrollTo({ top: boundingRect.top - 125, behavior: "smooth" })
      }
    }
  }, [selectedCondition])

  useEffect(() => {
    window.addEventListener("scroll", e => {
      if (window.scrollY > 1800) {
        setShowScrollUp(true)
      } else if (window.scrollY < 1800) {
        setShowScrollUp(false)
      }
    })
  }, [])

  return (
    <ConditionsLPWrapper>
      <ConditionsWeTreat onSubmit={setSelectedCondition} />
      {data.conditions.length > 0 && (
        <ConditionSections conditions={data.conditions} />
      )}
      <MedsVsTherapy />
      {data.other_specialties.length > 0 && (
        <OtherSpecialties specialties={data.other_specialties} />
      )}
      {data.therapeutic_approaches.length > 0 && (
        <TherapeuticApproaches approaches={data.therapeutic_approaches} />
      )}
      <MoreGoodDays />
      <FAQSection faqs={faqs} />

      <ScrollToTopButton
        onClick={() => {
          window.scrollTo(0, 0)
        }}
        show={showScrollUp}
      >
        <img src={ArrowUp} alt="scroll up to top" />
      </ScrollToTopButton>
    </ConditionsLPWrapper>
  )
}

export const ConditionsLanding = ({
  data,
}: {
  data: ConditionsPageProps
}): JSX.Element => {
  return (
    <>
      <SEO
        title="Conditions Cerebral Treats"
        titleSuffix="Expert Online Therapy + Mental Health Care on Your Schedule"
        description="Cerebral experts can treat anxiety, depression, insomnia, and more. Learn about the care you can get today."
        url="conditions-we-treat/"
        schemaMarkup={organizationSchemaMarkup}
      />
      <Navigation />
      <ConditionsLPBody data={data} />
    </>
  )
}
