import React, { useState, useRef, useEffect } from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownWrapper,
  ErrorTooltip,
} from "./styled"
import Triangle from "./assets/triangle.svg"
import ChevronUp from "./assets/chevron-up.svg"
import ChevronDown from "./assets/chevron-down.svg"
import CircleX from "./assets/circle-x.svg"
import { colors } from "../../themes/colors"
import { useWindowSize } from "../../../components/hooks"

// Note this is a controlled component and requires a value and an onSelect function to be passed in as props

interface ICustomDropdownProps {
  options: string[]
  onSelect: (option: string) => void
  LeftIcon: ({ fill }: { fill: string }) => JSX.Element
  value: string | null
  error?: string
  isDisabled?: boolean
  placeholder?: string
  onClear?: () => void
}

export const CustomDropdown = ({
  options,
  onSelect,
  LeftIcon,
  value,
  error,
  isDisabled = false,
  placeholder,
  onClear,
}: ICustomDropdownProps) => {
  const [text, setText] = useState<string>("")
  const [isOpen, setIsOpen] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLInputElement>(null)
  const [localOptions, setLocalOptions] = useState<string[]>(options)
  const optionsRefs = useRef<Array<HTMLDivElement | null>>([])

  const { width } = useWindowSize()
  const isMobile = width < 769

  useEffect(() => {
    setLocalOptions(options)
  }, [options])

  useEffect(() => {
    if (!value) {
      setCurrentIndex(-1)
    }
    setText(value || "")
  }, [value])

  const toggleDropdown = () => {
    if (dropdownRef.current && isMobile) {
      const top = dropdownRef.current.getBoundingClientRect().top
      window.scrollTo({ top: top + window.scrollY - 130, behavior: "smooth" })
    }
    if (isDisabled) return
    inputRef.current?.focus()
    setIsFocused(true)
    setIsOpen(!isOpen)
  }

  const closeDropdown = () => {
    setIsFocused(false)
    setText(value ?? "")
    setIsOpen(false)
  }

  const handleInputChange = (value: string) => {
    if (!isOpen) setIsOpen(true)
    setText(value)
    setLocalOptions(
      options.filter(option =>
        option.toLowerCase().includes(value.toLowerCase())
      )
    )
  }

  const handleSelectOption = (index: number) => {
    onSelect(localOptions[index])
    setIsOpen(false)
    setText("")
    handleInputChange("")
    setIsSelected(true)
  }

  const handleXClick = () => {
    setIsSelected(false)
    setText("")
    handleInputChange("")
    onClear?.()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (isDisabled) return
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault()
        if (isOpen) {
          setCurrentIndex(prevIndex => {
            const newIndex = (prevIndex + 1) % localOptions.length
            optionsRefs.current[newIndex]?.scrollIntoView({ block: "nearest" })
            return newIndex
          })
        } else {
          setIsOpen(true)
        }
        break
      case "ArrowUp":
        event.preventDefault()
        if (isOpen) {
          setCurrentIndex(prevIndex => {
            const newIndex =
              (prevIndex - 1 + localOptions.length) % localOptions.length
            optionsRefs.current[newIndex]?.scrollIntoView({ block: "nearest" })
            return newIndex
          })
        } else {
          setIsOpen(true)
        }
        break
      case "Enter":
        event.preventDefault()
        if (isOpen) {
          handleSelectOption(currentIndex)
          onSelect(localOptions[currentIndex])
        }
        setIsOpen(!isOpen)
        break
      default:
        break
    }
  }

  return (
    <DropdownWrapper
      ref={dropdownRef}
      onClick={toggleDropdown}
      onBlur={closeDropdown}
      onKeyDown={handleKeyDown}
      error={!!error}
      isFocused={isFocused}
      data-testid={placeholder}
    >
      <LeftIcon
        fill={isDisabled ? colors.c_grey[500] : colors.c_primary[600]}
      />
      <input
        onChange={e => handleInputChange(e.currentTarget.value)}
        ref={inputRef}
        value={text}
        placeholder={placeholder ?? "Select an option"}
        disabled={isDisabled}
        type="text"
      />
      {isSelected ? (
        <img src={CircleX} alt="" onClick={handleXClick} />
      ) : (
        <img src={isOpen ? ChevronUp : ChevronDown} alt="" />
      )}

      {isOpen && (
        <DropdownMenu>
          {localOptions.map((option, index) => (
            <DropdownItem
              data-testid={`option-${index}`}
              key={index}
              selected={index === currentIndex}
              ref={el => (optionsRefs.current[index] = el)}
              onMouseDown={() => handleSelectOption(index)}
              onMouseOver={() => setCurrentIndex(index)}
            >
              {option}
              {value === option && " (selected)"}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
      {error && !isOpen && (
        <ErrorTooltip>
          <img src={Triangle} alt="" />
          <p>{error}</p>
        </ErrorTooltip>
      )}
    </DropdownWrapper>
  )
}
